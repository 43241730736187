<template>
  <button type="button" @click.prevent="openMobileMenu" class="mobile-nav-toggle d-lg-none"><i class="icofont-navigation-menu"></i></button>
  <header id="header" class="fixed-top ">
    <div class="container d-flex align-items-center">
      <h1 class="logo mr-auto"><router-link :to="{name: 'home'}" href="#"><img class="bg-white br-8 p-1 pl-2 pr-2" src="@/assets/images/omiyago.png"/></router-link></h1>
      <nav class="nav-menu d-none d-lg-block">
        <ul>
          <li :class="[!isMenu && isPage=='home' ? 'active' : '']"><router-link :to="{name: 'home'}" :class="[isPage!='home' ? 'text-dark' : '']" @click.prevent="toContent()" href="#">Home</router-link></li>
          <li :class="[isMenu=='content-about' || isPage=='about' ? 'active' : '']"><a :class="[isPage!='home' ? 'text-dark' : '']" @click.prevent="toContent('content-about')" href="#">Tentang</a></li>
          <li :class="[isMenu=='content-service' ? 'active' : '']"><a :class="[isPage!='home' ? 'text-dark' : '']" @click.prevent="toContent('content-service')" href="#">Layanan</a></li>
          <li :class="[isMenu=='content-mitra' ? 'active' : '']"><a :class="[isPage!='home' ? 'text-dark' : '']" @click.prevent="toContent('content-mitra')" href="#">Mitra</a></li>
          <li :class="[isMenu=='content-store' ? 'active' : '']"><a :class="[isPage!='home' ? 'text-dark' : '']" @click.prevent="toContent('content-store')" href="#">Toko</a></li>
          <li :class="[isMenu=='content-product' ? 'active' : '']"><a :class="[isPage!='home' ? 'text-dark' : '']" @click.prevent="toContent('content-product')" href="#">Produk</a></li>
        </ul>
      </nav>
    </div>
  </header>
  <nav class="mobile-nav d-lg-none">
    <ul>
      <li :class="[!isMenu && isPage=='home' ? 'active' : '']"><router-link :to="{name: 'home'}" :class="[isPage!='home' ? 'text-dark' : '']" @click.prevent="toContent(false, true)" href="#">Home</router-link></li>
      <li :class="[isMenu=='content-about' || isPage=='about' ? 'active' : '']"><a :class="[isPage!='home' ? 'text-dark' : '']" @click.prevent="toContent('content-about', true)" href="#">Tentang</a></li>
      <li :class="[isMenu=='content-service' ? 'active' : '']"><a :class="[isPage!='home' ? 'text-dark' : '']" @click.prevent="toContent('content-service', true)" href="#">Layanan</a></li>
      <li :class="[isMenu=='content-mitra' ? 'active' : '']"><a :class="[isPage!='home' ? 'text-dark' : '']" @click.prevent="toContent('content-mitra', true)" href="#">Mitra</a></li>
      <li :class="[isMenu=='content-store' ? 'active' : '']"><a :class="[isPage!='home' ? 'text-dark' : '']" @click.prevent="toContent('content-store', true)" href="#">Toko</a></li>
      <li :class="[isMenu=='content-product' ? 'active' : '']"><a :class="[isPage!='home' ? 'text-dark' : '']" @click.prevent="toContent('content-product', true)" href="#">Produk</a></li>
    </ul>
  </nav>
  <div class="mobile-nav-overly hide_element" style="display: block;"></div>
</template>

<script>
import { ref } from 'vue'
import { scrolToElement } from '../helpers/global.js'

export default {
  setup(){
      let isMenu = ref(false)
      return {isMenu}
  },
  methods: {
      toContent(elm = false, isMobile = false){
          if(elm){
              if(this.isPage!='home'){
                this.$router.push({'name':'home'});
              }
              setTimeout(() => { scrolToElement(elm) }, 200)
          }
          this.isMenu = elm;
          if(isMobile) this.openMobileMenu();
      },
      openMobileMenu(){
        $('body').toggleClass('mobile-nav-active');
        $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu')
        $('.mobile-nav-toggle i').toggleClass('icofont-close');
        $('.mobile-nav-overly').toggleClass('hide_element');
      }
  },
  props:{
      isPage: { type: String, default: 'home'}
  }
};
</script>