<template>
  <Header :isPage="isRouteName.name"></Header>
  <router-view></router-view>
  <Footer :isPage="isRouteName.name"></Footer>
</template>

<script>
import Header from "./Header";
import Footer from "./Footer";
export default {
  computed: {
      isRouteName(){
          return this.$route;
      }
  },
  components: { Header, Footer },
  name: "home",
  mounted() {
    document.title = "Omiyago - Pusat Oleh-Oleh dan Camilan Nusantara";
  },
};
</script>
