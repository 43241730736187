const state = { userOmiyago: null, secretUserOmiyago: null};
const getters = {
  isAuthenticated: (state) => !!state.userOmiyago,
  stateUser: (state) => state.userOmiyago,
};

const actions = {
  async logIn({commit}, userOmiyago) { await commit("setUser", userOmiyago); },
  async logOut({ commit }) { commit("logout"); },
};

const mutations = {
  setUser(state, userOmiyago) { state.userOmiyago = userOmiyago; },
  logout(state) { state.userOmiyago = null; },
};

export default { state, getters, actions, mutations};
