<template>
  <footer id="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-3 footer-contact">
            <h3 class="mb-3"><img class="h-50p" src="@/assets/images/omiyago.png"/></h3>
            <div class="fw-bold">Head Office</div>
            <p class="capitalize">
              gelong baru tengah no 38 tomang grogol petamburan jakarta barat 11410
            </p>
            <div class="fw-bold mt-2">Operasional</div>
            <p class="capitalize">
              permata puri media jalan safir 3 blok C1 no 22 kembangan jakarta barat 11610
            </p>
            <div class="fw-bold mt-2">Offline Store</div>
            <p class="capitalize">
              <i class="bx bx-chevron-right"></i><span class="ml-2">Sarinah Indonesia, Jakarta</span>
            </p>
            <p class="capitalize">
               <i class="bx bx-chevron-right"></i><span class="ml-2">Breeve Hills, Puncak Bogor</span>
            </p>
          </div>
          <div class="col-12 col-lg-3 footer-links">
            <h4>Link Terkait</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="http://joharizein.foundation" target="_blank">Johari Zein Foundation</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="https://elani.co.id" target="_blank">Elang Labuan Murni</a></li>
              <li><i class="bx bx-chevron-right"></i> <router-link :to="{name: 'about'}" href="#">Tentang Kami</router-link></li>
              <li><i class="bx bx-chevron-right"></i> <a @click.prevent="toContent('content-service')" href="#">Layanan</a></li>
              <li><i class="bx bx-chevron-right"></i> <a @click.prevent="toContent('content-mitra')" href="#">Mitra UMKM</a></li>
              <li><i class="bx bx-chevron-right"></i> <a @click.prevent="toContent('content-product')" href="#">Produk Omiyago</a></li>
              <li><i class="bx bx-chevron-right"></i> <a @click.prevent="toContent('content-faq')" href="#">F.A.Q</a></li>
            </ul>
          </div>
          <div class="col-12 col-lg-2 footer-links">
            <h4>Beli Produk</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="https://www.tokopedia.com/omiyago" target="_blank">Omiyago Tokopedia</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="https://shopee.co.id/omiyago" target="_blank">Omiyago Shopee</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="https://www.jd.id/shop/Omiyago_10087819.html" target="_blank">Omiyago Jd.id</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="https://food.id/o/omiyago" target="_blank">Omiyago Food.id</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="https://www.lazada.co.id/shop/omiyago" target="_blank">Omiyago Lazada</a></li>
            </ul>
          </div>
          <div class="col-12 col-lg-2 footer-links">
            <h4>Sosial Media</h4>
            <ul>
                <li><img class="sm-icon" src="@/assets/images/icons/facebook.svg"/> <a class="ml-2" href="https://www.facebook.com/omiyago.official/" target="_blank">omiyago.official</a></li>
                <li><img class="sm-icon" src="@/assets/images/icons/instagram.svg"/> <a class="ml-2" href="https://www.instagram.com/omiyago_official" target="_blank">@omiyago_official</a></li>
                <li><img class="sm-icon" src="@/assets/images/icons/youtube.svg"/> <a class="ml-2" href="https://www.youtube.com/c/Omiyago" target="_blank">Omiyago Official</a></li>
                <li><img class="sm-icon" src="@/assets/images/icons/tiktok.svg"/> <a class="ml-2" href="https://www.tiktok.com/@omiyago_official" target="_blank">@omiyago_official</a></li>
              </ul>
          </div>
          <div class="col-12 col-lg-2 footer-links">
            <h4>Kontak Kami</h4>
            <ul>
              <li><i class="bx bx-phone"></i> <a href="tel:02215601525" target="_blank">+6221 560 1525</a></li>
              <li><i class="bx bxl-whatsapp"></i> <a href="https://wa.me/6281311295511?text=Halo%20Omiyago%2C%20saya%20ingin%20bertanya" target="_blank">+6281 311 295 511</a></li>
              <li><i class="bx bx-envelope"></i> <a href="mailto:cs@omiyago.com" target="_blank">cs@omiyago.com</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="container d-md-flex py-4 align-items-center justify-content-center">
      <div class="text-center">
        <div class="copyright">
          &copy; Copyright <strong><span>PT Global Kuliner Ciptarasa</span></strong>. 2022
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { scrolToElement } from '../helpers/global.js'
export default {
  props:{
      isPage: { type: String, default: 'home'}
  },
  methods: {
      toContent(elm){
        if(this.isPage!='home'){
          this.$router.push({'name':'home'});
        }
        setTimeout(() => { scrolToElement(elm) }, 200)
      }
  }
};
</script>
