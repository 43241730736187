import { createRouter, createWebHistory, useRouter } from 'vue-router'
import store from "../config/store";
import { scrolToTop } from '../helpers/global.js'

//define a routes
const routes = [
    {
        path: '/', alias:'/home', name: 'home',
        meta: { readyPage:true },
        component: () => import('@/views/Home/Index.vue')
    },
    {
        path: '/tentang', alias:'/about', name: 'about',
        meta: { readyPage:true },
        component: () => import('@/views/Home/About.vue')
    },
    {
        path: '/pagenotfound', name: 'pagenotfound',
        meta: { readyPage:true },
        component: () => import('@/views/Page/NotFound.vue')
    },

]

//create router
const router = createRouter({ history: createWebHistory(), useroute: useRouter(), routes })

router.beforeEach((to, from, next) => {
    if(to.matched.some((record) => record.meta.readyPage)) {
        scrolToTop(); next();
    }else{ next('/pagenotfound'); }
});

export default router